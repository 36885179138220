<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Details
    </mat-card-header>
    <mat-card-content>

        <hr>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" id="fancytable" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Sr.No</th>
                        <th>Event Name</th>
                        <th>marketID</th>
                        <th>GameID</th>
                        <th>Result</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let data of bmresult;let index=index">
                        <td>{{index+1}}
                        </td>

                        <td>{{data.eventName}}&nbsp; </td>
                        <td>{{data.marketId}}&nbsp; </td>
                        <td>{{data.gameId}} </td>
                        <td>{{data.runnername}} </td>
                        <td><button class="btn btn-info btn-sm" data-toggle="modal" data-target="#reversemodal"
                                (click)="shareRunner(data)">Reverse</button></td>

                    </tr>
                </tbody>
            </table>
        </div>

        <!-- popup -->
        <div id="reversemodal" class="modal fade" role="dialog" aria-labelledby="usermodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Details</span><span></span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                          <div *ngFor="let selection of runnerName">
                            <input type="radio" [id]="selection.selId" [value]="selection.selId" name="runner" (change)="onRunnerChange(selection.selId)">
                            <label class="margin-left" [for]="selection.selId">{{ selection.name }}</label>
                          </div>
                          <input type="radio" id="void" value="-1" name="runner" (change)="onRunnerChange('-1')">
                          <label class="margin-left" for="void">Void Market</label>
                        </div>
                        <div class="popup_col_12 button-row">
                          <button class="btn btn-info btn-sm" (click)="ReverseBM()">
                            Submit
                          </button>
                          <button data-dismiss="modal" class="btn btn-info btn-sm">
                            Cancel
                          </button>
                        </div>
                      </div>
                    <!-- <div class="modal-body">
                        <div class="popup_form_row">
                            <select #bookResult name="bookResult" class="form-control"
                                (change)="onRunnerChange($event.target.value)">
                                <option value="0">Selection</option>
                                <option *ngFor="let selection of runnerName" [value]="selection.selId">
                                    {{ selection.name }}
                                </option>
                                <option value="-1">Void Market</option>
                            </select>
                            <div class="popup_col_12 button-row">
                                <button class="btn btn-info btn-sm" (click)=" ReverseBM($event,bookResult.value) ">
                                    Submit
                                </button>
                                <button data-dismiss="modal" class="btn btn-info btn-sm" (click)=" ReverseBM($event,bookResult.value) ">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>