<mat-card class="content-wrapper">
    <mat-card-header class="title_center">
        Score
    </mat-card-header>
    <mat-card-content>
        <a class="btn btn-info btn-sm" (click)="addscoreform(1)" title="Add Score details"
            *ngIf="!showscoreform"><span>Add Score</span></a>
        <a class="btn btn-danger btn-sm" (click)="addscoreform(2)" title="Add Score details"
            *ngIf="showscoreform"><span>Close Score</span></a>
        <a class="btn btn-info btn-sm" (click)="addtvform(1)" title="Create User" *ngIf="!showtvform"><span>Add
                TV</span></a>
        <a class="btn btn-danger btn-sm" (click)="addtvform(2)" title="Create User" *ngIf="showtvform"><span>Close
                TV</span></a>
        <a class="btn btn-success btn-sm" (click)="addCasinoform(1)" title="Create User" *ngIf="!showcasinoform"><span>Add Casino</span></a>
        <a class="btn btn-danger btn-sm" (click)="addCasinoform(2)" title="Create User" *ngIf="showcasinoform"><span>Close
            Casino</span></a>
        <a class="btn btn-info btn-sm" data-toggle="modal" data-target="#Addmatchmodal"
            title="Add Match details"><span>Add Match details</span></a>
        <a class="btn btn-info btn-sm" data-toggle="modal" data-target="#Tickermodal" title="Add VRNL Ticker"
            (click)="getTicker()"><span>Add Ticker</span></a>
        <a class="btn btn-info btn-sm" data-toggle="modal" data-target="#LaserTickermodal" title="Add Laser Ticker"
            (click)="getLaserTicker()"><span>Add Laserbook Ticker</span></a>    
        <a class="btn btn-danger btn-sm" data-toggle="modal" data-target="#Confirmmodal"
            title="Delete All Score"><span>Delete All Score</span>
        </a>
        <a class="btn btn-info btn-sm" data-toggle="modal" data-target="#Serverswitchmodal"
            title="Delete All Score"><span>Source Switch</span>
        </a>
        <a class="btn btn-info btn-sm" data-toggle="modal" data-target="#mapfancy"
        title="Delete All Score"><span>Map Fancy</span>
    </a>
        <div style="padding:15px; padding: 20px;margin: 5px;border: 2px solid;box-shadow: 5px 4px #88888863;border-radius: 10px;"
            *ngIf="ScoreForm && showscoreform">
            <form [formGroup]="ScoreForm" (ngSubmit)="addScore()">
                <div style="font-size: 20px;text-align: center;font-weight: 600;">
                    <span>Score Setup</span>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3 col-xs-3 p-10">
                        <label> Sport : </label>
                        <select class="form-control" formControlName="selectsport" (change)="changeselectevent(1)">
                            <option value="0">Select Sport</option>
                            <option value="4">Cricket</option>
                            <option value="1">Soccer</option>
                            <option value="2">Tennis</option>
                        </select>
                    </div>
                    <div class="col-sm-3 col-xs-3 p-10">
                        <label> Event : </label>
                        <select class="form-control" formControlName="selectevent">
                            <option value="0">---- Select Eventname ----</option>
                            <option *ngFor="let high of highlights;trackBy:trckbyeventid" [value]="high.gameId">
                                {{high.name}} /
                                {{high.eventDate.split("T")[0]}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-3 col-xs-3 p-10">
                        <label> Event Id : </label>
                        <input type="text"
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || (event.charCode >= 48 && event.charCode <= 57))"
                            class="form-control" formControlName="event_id">
                    </div>
                    <div class="col-sm-3 col-xs-3 p-10">
                        <label> Score Id : </label>
                        <input type="text"
                            onkeypress="return ((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 8 || (event.charCode >= 48 && event.charCode <= 57))"
                            class="form-control" formControlName="score_id">
                    </div>
                </div>
                <div class="button-row">
                    <button mat-raised-button color="primary" [disabled]="ScoreForm.invalid" type="submit">Add</button>
                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                        type="button">Cancel</button>
                </div>
            </form>
        </div>

        <div style="padding:15px; padding: 20px;margin: 5px;border: 2px solid;box-shadow: 5px 4px #88888863;border-radius: 10px;"
            *ngIf="CasinoForm && showcasinoform">
            <form [formGroup]="CasinoForm" (ngSubmit)="addCasinoRound()">
                <div style="font-size: 20px;text-align: center;font-weight: 600;">
                    <span>Casino result</span>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-6 col-xs-6 p-10">
                        <label> Casino Type : </label>
                        <select class="form-control" formControlName="selectCasino">
                            <option value="">Select Casino</option>
                            <option *ngFor="let ctype of casinoTypes;" [value]="ctype.rid">{{ctype.name}}</option>
                        </select>
                    </div>
                    <div class="col-sm-6 col-xs-6 p-10">
                        <label> Round Id : </label>
                        <input type="text" class="form-control" formControlName="roundId">
                    </div>

                </div>
                <div class="button-row">
                    <button mat-raised-button color="primary" [disabled]="CasinoForm.invalid" type="submit">Update result</button>
                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                        type="button">Cancel</button>
                </div>
            </form>
        </div>

        <div style="padding:15px; padding: 20px;margin: 5px;border: 2px solid;box-shadow: 5px 4px #88888863;border-radius: 10px;"
            *ngIf="TvForm && showtvform">
            <form [formGroup]="TvForm" (ngSubmit)="addTv()">
                <div style="font-size: 20px;text-align: center;font-weight: 600;">
                    <span>TV Setup</span>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-4 col-xs-4 p-10">
                        <label> Sport :</label>
                        <select class="form-control" formControlName="sport" (change)="changeselectevent(2)">
                            <option value="0">Select Sport</option>
                            <option value="4">Cricket</option>
                            <option value="1">Soccer</option>
                            <option value="2">Tennis</option>
                        </select>
                    </div>
                    <div class="col-sm-4 col-xs-4 p-10">
                        <label> Event :</label>
                        <select class="form-control" formControlName="event" (change)="changeevent()">
                            <option value="0">---- Select Eventname ----</option>
                            <option *ngFor="let high of highlights;trackBy:trckbyeventid" [value]="high.gameId">
                                {{high.name}} /
                                {{high.eventDate.split("T")[0]}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-4 col-xs-4 p-10">
                        <label> Type :</label>
                        <select class="form-control" formControlName="livename" (change)="getTv()">
                            <option value="0">Select Type</option>
                            <option [value]="live" *ngFor="let live of liveTvTypes">{{live}}</option>
                            <!-- <option value="2">Bet365</option>
                            <option value="3">Skyexch</option>
                            <option value="3">Ulivesports</option>
                            <option value="4">G-Live</option>
                            <option value="5">Youtube</option> -->
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 p-10">
                        <label> Channel Ip / Link : <b>(For Sky and Diamond)</b></label>
                        <input type="text" class="form-control" formControlName="channelIp">
                    </div>
                    <div class="col-md-6 col-xs-12 p-10">
                        <label>Link2 : <b>(For Bet365)</b></label>
                        <input type="text" class="form-control" formControlName="channelIp2">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-xs-3 p-10">
                        <label> HDMI : <b>(For Diamond only)</b></label>
                        <input type="text" class="form-control" formControlName="hdmi">
                    </div>
                    <div class="col-sm-4 col-xs-3 p-10">
                        <label> Match Id : </label>
                        <input type="text" class="form-control" formControlName="matchId">
                    </div>
                    <div class="col-sm-4 col-xs-3 p-10">
                        <label>Channel No : <b>(For G-LIVE)</b></label>
                        <input type="text" class="form-control" formControlName="channelno">
                    </div>
                </div>
                <div class="button-row">
                    <button mat-raised-button color="primary" [disabled]="TvForm.invalid" type="submit">Add</button>
                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                        type="button">Cancel</button>
                    <button mat-raised-button color="primary" type="button" (click)="getTv()">Get</button>
                </div>
            </form>
        </div>

        <hr>
        <div class="table-responsive">
            <div id="preloader"></div>
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr class="heading">
                        <th>Sr.No</th>
                        <th>Event Id</th>
                        <th>Score Id</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let score of scorelist;let index=index">
                        <td>{{index+1}}</td>
                        <td>{{score.event_id}}</td>
                        <td>{{score.score_id}}</td>
                        <td><a class="btn btn-danger btn-xs" title="Delete Score"
                                (click)="deletesinglescore(score.event_id)"><span>Delete</span></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Match Details -->
        <div id="Addmatchmodal" class="modal fade" role="dialog" aria-labelledby="Addmatchmodal" aria-hidden="true">

            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Add Match Details</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="AddmatchdetailsForm">
                            <form [formGroup]="AddmatchdetailsForm" (ngSubmit)="addMatchdetails()">
                                <!-- <div>
                                    <label> Comp Id : </label>
                                    <input type="text" class="form-control" formControlName="competitionId">
                                </div>
                                <div>
                                    <label> Comp Name : </label>
                                    <input type="text" class="form-control" formControlName="competitionName">
                                </div> -->
                                <div>
                                    <label> Event Name (Diamond): </label>
                                    <input type="text" class="form-control" formControlName="name">
                                </div>
                                <div>
                                    <label> Event Id : </label>
                                    <input type="text" class="form-control" formControlName="id">
                                </div>
                                <div>
                                    <label> MarketId: </label>
                                    <input type="text" class="form-control" formControlName="marketId">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="AddmatchdetailsForm.invalid"
                                        type="submit">Add</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- score Info -->
        <div id="Confirmmodal" class="modal fade" role="dialog" aria-labelledby="Confirmmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row">
                            <label>Are you sure you want to delete all score data ?</label>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="button"
                                (click)="deletescoreall()">Yes</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Ticker -->
        <div id="Tickermodal" class="modal fade" role="dialog" aria-labelledby="Tickermodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Ticker Details</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div>
                            <label> Ticker : </label>
                            <!-- <input type="text" class="form-control" [(ngModel)]="Ticker"> -->
                            <textarea class="form-control textticker" [(ngModel)]="Ticker"></textarea>
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="submit"
                                (click)="addTicker(Ticker)">Add</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="LaserTickermodal" class="modal fade" role="dialog" aria-labelledby="LaserTickermodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Ticker Details</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div>
                            <label> Ticker : </label>
                            <input type="text" class="form-control" [(ngModel)]="Ticker">
                        </div>
                        <div class="popup_col_12 button-row">
                            <button mat-raised-button color="primary" type="submit"
                                (click)="addLaserTicker(Ticker)">Add</button>
                            <button mat-raised-button color="primary" data-dismiss="modal" type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Server Switch Info -->
        <div id="Serverswitchmodal" class="modal fade" role="dialog" aria-labelledby="Serverswitchmodal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="ServerswitchForm">
                            <form [formGroup]="ServerswitchForm" (ngSubmit)="Serverswitch()">
                                <div>
                                    <label> Server : </label>
                                    <select class="form-control" formControlName="selectserver">
                                        <option value="0">---- Select Server IP ----</option>
                                        <option *ngFor="let high of serverips;" [value]="high.ip">
                                            {{high.ip}}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label> IP : </label>
                                    <input type="text" class="form-control" formControlName="serverip">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="ServerswitchForm.invalid"
                                        type="submit">Add</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="mapfancy" class="modal fade" role="dialog" aria-labelledby="mapfancy" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>Confirm Box</span>
                        <button type="button" class="close" data-dismiss="modal">
                            <div class="close_new"><i class="fa fa-times-circle"></i> </div>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="popup_form_row" *ngIf="MapfancyForm">
                            <form [formGroup]="MapfancyForm" (ngSubmit)="Mapfancy()">
                          
                                <div>
                                    <label> diamondEventId : </label>
                                    <input type="text" class="form-control" formControlName="diamondEventId">
                                </div>
                                <div>
                                    <label> diamondMarketId : </label>
                                    <input type="text" class="form-control" formControlName="diamondMarketId">
                                </div>
                                <div>
                                    <label> skyEventId : </label>
                                    <input type="text" class="form-control" formControlName="skyEventId">
                                </div>
                                <div class="popup_col_12 button-row">
                                    <button mat-raised-button color="primary" [disabled]="MapfancyForm.invalid"
                                        type="submit">Add</button>
                                    <button mat-raised-button color="primary" data-dismiss="modal" (click)="resetForm()"
                                        type="button">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>