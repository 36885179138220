import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TkfancyService {
  BASEURL = environment.BASEURL1;
  APIURL=environment.APIURL;

  constructor(private http: HttpClient) { }

  GetTKAllevents(): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetTKAllevents`);
  }

  // GetTKfancyList(status,eventid): Observable<any> {
  //   return this.http.get(`${this.BASEURL1}/GetTKfancyList?status=${status}&eventid=${eventid}`);
  // }

  GetTKfancyList(status,eventid,source): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetAmanfancyList?status=${status}&eventid=${eventid}&source=${source}`);
  }
  GetTKsettledfancyList(eventid): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetTKsettledfancyList?eventid=${eventid}`);
  }

  AddTKFancy(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/AddTKFancy`, data);
  }
  UpdateTKFancyResult(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateTKFancyResult`, data);
  }
  updateTKFancyStatus(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateTKFancyStatus`, data);
  }

  Addfancyresult(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_fancy_result`, data);
  }

  Verifyfancyresult(eventid,fancynamme,status): Observable<any> {
    return this.http.get(`${this.APIURL}/api/fancy_result/${eventid}/${fancynamme}/${status}`);
  }
  verifyAllSkyFancyResult(eventId,status){
    return this.http.post(`${this.BASEURL}/verifyAllSkyFancyresult/${eventId}/${status}`, null);
  }
  updateAllSkyFancyResult(eventId,status){
    return this.http.post(`${this.BASEURL}/updateAllSkyFancyResult/${eventId}/${status}`, null);
  }

  VrnlFancyLive(gameId,result){
    return this.http.post(`https://111111.info/pad=81/vrnlFancyLive?action=setResult&gameId=${gameId}&result=${result}`, null);
  }

   reverseSetteledFancy(gameId,run){
    return this.http.post(`https://444444.club/pad=81/vrnlFancyLive/?action=reverseSetteledFancy&gameid=${gameId}&run=${run}`, null);
  }
  SettleMultiSportBook(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/multiCancelMarkets`, data);
  }

}
