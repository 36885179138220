import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettlementService {
  BASEURL = environment.BASEURL1;
  APIURL=environment.APIURL;
  baseUrl: string;
  constructor(private http: HttpClient,private router :Router) {
   this.baseUrl='https://222222.digital/pad=81'
  }

  closedGames(date: string, sportId: string) {
    return this.http.get(
      `${this.baseUrl}/games?date=${date}&sportId=${sportId}`
    );
  }

  setResult(sportId: string, marketId: string) {
    // result?sportId=id&marketId=mid

    return this.http.get(
      `${this.baseUrl}/result?sportId=${sportId}&marketId=${marketId}`
    );
  }

  getVirtual(date: string) {
    return this.http.get(`${this.baseUrl}/virtualGames?date=${date}`);
  }

  settleVirtual(type, marketid, runsSelection) {
    return this.http.get(
      `${this.baseUrl}/settleVirtualGames?type=${type}&marketId=${marketid}&winner=${runsSelection}`
    );
  }

  voidBets(marketId) {
    return this.http.get(`${this.baseUrl}/void/${marketId}`);
  }

  getBmMarkets(date: string) {
    return this.http.get(`${this.baseUrl}/bmGames?date=${date}`);
  }

  settleTNPLBmMarket(marketId: string, result: number) {
    return this.http.get(
      `${this.baseUrl}/settleBmGame?marketId=${marketId}&winnerSelId=${result}`
    );
  }

settleBmMarket(data): Observable<any> {
  return this.http.post(`${this.BASEURL}/updateBmResult`, data);
}

  getkabaddiGames(date: string){
    return this.http.get(`${this.baseUrl}/kabaddiGames?date=${date}`);
  }

  settleKabaddiGames(type:string,marketId: string, result: number){
    return this.http.get(
      `${this.baseUrl}/settleKabaddiGames?type=${type}&marketId=${marketId}&winner=${result}`
    );
  }
  reversePremiumGame(gameId){
    return this.http.get(`${this.baseUrl}/reversePremiumGame/${gameId}`);
  }
  reverseBMGame(gameId){
    return this.http.get(`${this.baseUrl}/reverseBMGame/${gameId}`);
  }

}
