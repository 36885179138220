import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject, interval, Subscription } from 'rxjs';
import { AllapiService } from '../Services/allapi.service';
import { NotificationService } from '../Services/notification.service';
import { TokenService } from '../Services/token.service';
import { SessionUpdService } from './session-upd.service';
import { element } from 'protractor';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-session-upd',
  templateUrl: './session-upd.component.html',
  styleUrls: ['./session-upd.component.scss']
})
export class SessionUpdComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  AddFancyForm: FormGroup;
  MapEvent: FormGroup
  fancyList: any = [];
  allimportFancy: any;
  importfancyinterval: Subscription;
  checknewfancy: Subscription;
  selectedItems: any[] = [];
  Allimportedfancies: any = [];
  options = [
    {
      status: 0,
      type: "Active"
    },
    {
      status: 1,
      type: "Settled"
    },
    {
      status: 2,
      type: "Cancelled"
    }];
  selectedStatus: any = "0"
  loader = false;
  selectevent: any="";
  fancydata: any;
  AllfancyList: any;
  Oldimportedfancy: any = [];
  Settlesession: any;
  highlights: any;
  Search: any;
  selectfromdate: Date;
  selectedUid: any;
  paramselectedevnet: any;
  gameid: any;
  runs: string = '';
  runsForm: FormGroup;
  checktnsettle: boolean = false;
  getusertype: any;
  isAllSelected: boolean = false;
  multiID:any
  constructor(private fb: FormBuilder, private allapi: SessionUpdService,private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private notifyService: NotificationService, private tokenService: TokenService) {
    $(document).on("focus", 'input[type="search"]', function () {
      var ele: any = $('input[type="search"]').val();
      var table = $("#fancytable").DataTable();
      table.search(ele, false, false, false).draw();
    });
    this.getusertype= localStorage.getItem('usertype');
    // console.log(this.getusertype)
    this.route.params.subscribe((params) => {
      this.selectedUid = params.selectedUid
      console.log(this.selectedUid)
      if (this.selectedUid) {
        this.getEventlist();
        this.selectevent = + params.selectedUid
      } else {
        this.selectevent = "";
      }
    })
    this.runsForm = this.formBuilder.group({
      runs: ['', Validators.required], 
    });

  }


  ngOnInit(): void {
    // this.selectfromdate = new Date(new Date());
    // this.selectfromdate = new Date(new Date().setHours(0, 0, 0));

    this.initUserForm();
    this.getFancyList();
    this.getEventlist();
    this.importfancyinterval = interval(25000).subscribe((x => {
      this.getEventlist();
    }));
    this.initDatatable();
  }

  initUserForm() {
    this.AddFancyForm = this.fb.group({
      fancyname: ['', Validators.required],
      date: ['', Validators.required],
      eventname: ['', Validators.required],
      eventid: ['', Validators.required],
      result: [null],
      compname: [null],
      status: [0]
    });

    this.MapEvent= this.fb.group({
      eventid: ['', Validators.required],
      neweventId: ['', Validators.required],
    });
  }

  initDatatable() {
    // console.log('table')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      processing: true,
      responsive: true,
      deferRender: true,
      order: [[0, 'desc']]
    };
  }

  getEventlist() {
    this.allapi.GetAlleventList().subscribe(resp => {
      this.highlights = resp.data;
      // console.log( this.highlights)
      // this.highlights.forEach(high => {
      //   if (high.eventid == this.selectedUid) {
      //     this.paramselectedevnet = high.eventname
      //     this.selectevent = this.paramselectedevnet;
      //     console.log('Selected event found:', high.eventname);

      //   } else {
      //     this.paramselectedevnet
      //     this.selectevent 
      //   }
      // })



    })
  }

  getFancyList(term?: string) {    
    this.selectedItems = []; 
    this.isAllSelected =false; 
    this.loader = true;
    if (this.selectedStatus != null && this.selectevent != "") {
    this.allapi.GetfancyList(this.selectedStatus, this.selectevent, 1).subscribe(resp => {
      // console.log(resp);
      this.fancyList = resp.data;
      this.loader = false;
      this.rerender();
    })
  }
  }
  onchangeinput(d) {
    if (d != null) {
      var event = [`${d.getFullYear()}`, `0${d.getMonth() + 1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-");
    } else {
      event = "";
    }
    // console.log(d, [`${d.getFullYear()}`, `0${d.getMonth()+1}`.substr(-2), `0${d.getDate()}`.substr(-2)].join("-"));
    var table = $("#fancytable").DataTable();
    table.search(event, false, false, false).draw();
  }

  getAllFancyList() {
    this.allapi.GetAllfancyList().subscribe(resp => {
      this.AllfancyList = resp.data;
    })
  }

  resetForm() {
    this.initUserForm();
  }

  addManualeventskyMap() {
    if (!this.selectevent) {
      this.notifyService.error("select Event");
      return false;
    }
    this.allapi.GeteventsDetails(this.selectevent).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.message);
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  MappingEvent(){
    var data = {
      "eventid": this.MapEvent.value.eventid,
      "neweventId": this.MapEvent.value.neweventId,
    }
    this.allapi.MapMissingEvent(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
    
  }
  addSessions() {
    var data = {
      "Fancy": this.AddFancyForm.value.fancyname,
      "Status": 0,
      "Date": this.AddFancyForm.value.date,
      "Eventname": this.AddFancyForm.value.eventname,
      "Eventid": this.AddFancyForm.value.eventid,
      "Result": null,
      "CompititionName": null,
      "type": null
    }
    // console.log(data)
    this.allapi.AddFancy(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  vrnlfancylive(gameId, result) {
    this.allapi.VrnlFancyLive(gameId, result).subscribe((res) => {
      // console.log(res);
    })
  }
  openreversesettlemodal(g: any) {
    console.log("click")
    this.gameid = g
  }
  reverseSetteledFancy() {
    if(this.gameid==null){
      this.notifyService.error("No bets found on vrnl");
    }
    else{
      if (this.checktnsettle) {
        return
      }
      this.checktnsettle = true;
      if (this.runsForm.valid) {
        const runsValue = this.runsForm.get('runs').value;
        this.allapi.reverseSetteledFancy(this.gameid,runsValue).subscribe((data :any) => {
         console.log(data)
         if(data?.errorCode == 0){
          this.getFancyList();
          // $("[data-dismiss=modal]").trigger("click");
          this.notifyService.success("Success");
         }
         else
         {
          this.notifyService.error("Error");
         }
         $("[data-dismiss=modal]").trigger("click");
         setTimeout(()=>{
          this.checktnsettle = false;
        },1000)
      }, err => {
        this.checktnsettle = false;
        console.log(err);
      });
      }
    }
  
  }
  Updateresult(fancy) {
    if (fancy.Result == null) {
      this.notifyService.error("Please Enter Result");
      return false;
    }
    var data = {
      "Id": fancy.Id,
      "Status": 1,
      "Result": fancy.Result,
      "Settledby": this.tokenService.getUsername(),
      "Reversed": null
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: fancy.Result,
      fsource: 0
    }
    this.storefancyresult(resultData);
    this.vrnlfancylive(fancy.gameId, fancy.Result)
    this.allapi.UpdateFancyResult(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  updatestatus(fancy) {
    var data = {
      "Id": fancy.Id,
      "Status": fancy.Status
    }
    this.allapi.updateFancyStatus(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        // $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  // clearFancy(){
  //   this.loader = true;
  //   this.allapi.GetfancyList(this.selectedStatus,this.selectevent).subscribe(resp => {
  //     this.fancyList = resp.data;
  //     this.loader = false;
  //     this.rerender();
  //   })
  //   // var data = {
  //   //   "Id":fancy.Id,
  //   //   "Status": 1,
  //   //   "Result": fancy.Result,
  //   //   "Settledby": this.tokenService.getUsername(),
  //   //   "Reversed":null
  //   // }
  // }

  toggleSelection(item: any, isChecked: boolean) {
    const index = this.selectedItems.findIndex(selectedItem => selectedItem.Id === item.Id);
    if (isChecked && index === -1) {
      this.selectedItems.push(item);
    } else if (!isChecked && index !== -1) {
      this.selectedItems.splice(index, 1);
    }
    this.isAllSelected = this.fancyList.length === this.selectedItems.length;
    this.multiID = this.selectedItems.map(selectedItem => selectedItem.Id).join(', ');
    console.log("Selected IDs:", this.multiID);
}

  
  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }
  selectAll(isChecked: boolean) {
    this.selectedItems = isChecked ? [...this.fancyList] : [];
    this.isAllSelected = isChecked;
    this.multiID = this.selectedItems.map(item => item.Id).join(', ')
    // console.log("Selected IDs:",this.multiID);
    // console.log("Selected IDs:", this.selectedItems.map(item => item.sbmarketid).join(', '));
  }

  fancyinfodata(data) {
    this.fancydata = data;
    this.Settlesession = data.Result;
    //console.log(this.fancydata);
  }
  cancelmultifancy(){
    var data = {
      "fIds": this.multiID,
      "userId": this.tokenService.getuid(),
      "source":0
    }
    this.allapi.SettleMultiSportBook(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success("Market Canceled Successfully...");
      } else {
        this.notifyService.error(data.message);
      }
    })
  }

  cancelfancy(fancy) {
    var data = {
      "Id": fancy.Id,
      "Status": 2,
      "Result": -1,
      "Settledby": this.tokenService.getUsername(),
      "Reversed": null
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: -1,
      fsource: 0
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateFancyResult(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  reversefancy(fancy) {
    var data = {
      "Id": fancy.Id,
      "Status": 0,
      "Result": null,
      "Settledby": this.tokenService.getUsername(),
      "Reversed": this.tokenService.getUsername()
    }
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: null,
      fsource: 0
    }
    this.storefancyresult(resultData);
    this.allapi.UpdateFancyResult(data).subscribe(data => {
      if (data.status == "Success") {
        this.getFancyList();
        $("[data-dismiss=modal]").trigger("click");
        this.notifyService.success(data.result);
      } else {
        this.notifyService.error(data.result);
      }
    })
  }

  updateUnverified(fancy) {
    let resultData = {
      matchBfId: fancy.Eventid,
      fancyName: fancy.Fancy,
      result: fancy.Result,
      fsource: 0
    }
    //console.log(fancy,resultData);

    this.storefancyresult(resultData);
  }

  storefancyresult(fancy) {
    this.allapi.Addfancyresult(fancy).subscribe(data => {
      // console.log(data)
      this.notifyService.success(data.result);
    })
  }

  updateAllpending() {
    this.allapi.GetAllfancyList()
  }

  verifyfancyresult(fancy) {
    // console.log(fancy)

    if (fancy.CompitionName == null) {
      this.allapi.Verifyfancyresult(fancy.Eventid, fancy.Fancy, 0).subscribe(resp => {
        // console.log(resp.result)
        fancy.CompitionName = resp.result;
        if (resp.result == null) {
          this.notifyService.success("Not Yet Settled");
        }
      })
    } else {
      fancy.CompitionName = null;
    }

  }

  verifyfancyresult1(fancy) {
    // console.log(fancy)

    if (fancy.CompitionName == null) {
      this.allapi.Verifyfancyresult(fancy.Eventid, fancy.Fancy, 0).subscribe(resp => {
        this.fancyList.forEach((ele) => {
          if (ele.Eventid == fancy.Eventid && ele.Fancy == fancy.Fancy) {
            ele.CompitionName = resp.result;
          }
        })
      })
    } else {
      fancy.CompitionName = null;
    }

  }

  verifyAlldiamondfancyresult(eventId) {
    this.allapi.VerifyAlldiamondresult(eventId,this.selectedStatus).subscribe((res: any) => {
      // console.log(res.data);
      res.data.forEach(element => {
        this.verifyfancyresult1(element)
      });

    })
  }

  UpdateAlldiamondfancyresult(eventId) {
    this.allapi.UpdateAlldiamondresult(eventId,this.selectedStatus).subscribe((resp: any) => {
      // console.log(resp?.message);
      if (resp) {
        this.notifyService.success(resp?.message);
      }
    })
  }

  trackfancyid(index, fancy) {
    return fancy.Id;
  }
  trckbyeventid(index, high) {
    return high.gameId;
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.importfancyinterval.unsubscribe();
    // this.checknewfancy.unsubscribe();
  }

  rerender() {
    this.selectfromdate = null;
    this.onchangeinput(null)
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
