import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionUpdService {
  BASEURL = environment.BASEURL1;
  APIURL=environment.APIURL;

  constructor(private http: HttpClient) { }

  // GetfancyList(status,eventid): Observable<any> {
  //   return this.http.get(`${this.BASEURL1}/GetfancyList?status=${status}&eventid=${eventid}`);
  // }

  GetfancyList(status,eventid,source): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetAmanfancyList?status=${status}&eventid=${eventid}&source=${source}`);
  }
  GeteventsDetails(eventid): Observable<any> {
    return this.http.get(`${this.BASEURL}/GeteventsDetails/${eventid}`);
  }
  GetsettledfancyList(eventid): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetsettledfancyList?eventid=${eventid}`);
  }
  GetAllfancyList(): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetAllfancy`);
  }

  GetAlleventList(): Observable<any> {
    return this.http.get(`${this.BASEURL}/GetAllevents`);
  }

  importfancybymatch(id): Observable<any> {
    return this.http.get(`${this.APIURL}/api/bm_fancy/${id}`);
  }

  AddFancy(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/AddFancy`, data);
  }
  MapMissingEvent(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/mapMissingEvent`, data);
  }

  UpdateFancyResult(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateFancyResult`, data);
  }

  updateFancyStatus(data): Observable<any> {
    return this.http.put(`${this.BASEURL}/updateFancyStatus`, data);
  }

  Addfancyresult(data): Observable<any> {
    return this.http.post(`${this.APIURL}/api/store_fancy_result`, data);
  }

  Verifyfancyresult(eventid,fancynamme,status): Observable<any> {
    return this.http.get(`${this.APIURL}/api/fancy_result/${eventid}/${fancynamme}/${status}`);
  }

  UpdateAlldiamondresult(eventId,status){
    return this.http.post(`${this.BASEURL}/updateAllDiamondFancyResult/${eventId}/${status}`,null);
  }

  VerifyAlldiamondresult(eventId,status){
    return this.http.post(`${this.BASEURL}/verifyAllDiamondFancyresult/${eventId}/${status}`,null);
  }

  VrnlFancyLive(gameId,result){
    return this.http.post(`https://111111.info/pad=81/vrnlFancyLive?action=setResult&gameId=${gameId}&result=${result}`, null);
  }

  reverseSetteledFancy(gameId,run){
    return this.http.post(`https://444444.club/pad=81/vrnlFancyLive/?action=reverseSetteledFancy&gameid=${gameId}&run=${run}`, null);
  }

  SettleMultiSportBook(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/multiCancelMarkets`, data);
  }

}
