import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BmResultService {

  APIURL=environment.APIURL;
  BASEURL= environment.BASEURL1;
  
  constructor(private http: HttpClient) { }

  GetBmResult(): Observable<any> {
    return this.http.get(`${this.BASEURL}/getBmsettledResults`);
  }

  GetReverseBmResult(data): Observable<any> {
    return this.http.post(`${this.BASEURL}/reverseBmResult`,data);
  }
}
