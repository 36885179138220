import { Component, OnInit ,Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SidenavService } from '../Services/sidenav.service';
import { TokenService } from '../Services/token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;
  toggleActive:boolean = false;
  constructor(private sidenavService: SidenavService,private tokenService: TokenService,private router: Router) { }

  ngOnInit(): void {
  }

  toggleRightSidenav() {
		this.toggleActive = !this.toggleActive;
		this.sidenavService.toggle();
  }

  logout() {
    window.location.reload();
    this.tokenService.removeToken();
    this.tokenService.removeMMToken();
    this.tokenService.removeUsername();
    this.tokenService.removeUsername();
    this.router.navigateByUrl("login");
  }

}
