<div class="header-container" [class.is-mobile]="mobileQuery.matches">
    <!-- <div id="preloader"></div> -->
    <app-header [inputSideNav]="snav"></app-header>
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="true"
        [style.marginTop.px]="mobileQuery.matches ? 56 : 56">
        <mat-sidenav #snav (click)="snav.toggle()" [mode]="mobileQuery.matches ? 'over' : 'over'" class="sidenav"
            opened="false" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list>
                <app-sidebar [inputSideNav]="snav"></app-sidebar>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>