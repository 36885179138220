import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BmResultService } from './bm-result.service';
import { NotificationService } from '../Services/notification.service';

@Component({
  selector: 'app-bm-result',
  templateUrl: './bm-result.component.html',
  styleUrls: ['./bm-result.component.scss']
})
export class BmResultComponent  implements OnInit,OnDestroy,AfterViewInit {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bmresult:any
  runnerName:any
  selectedGameId:any
  selID:any
  constructor(private BmResult:BmResultService,private notifyService:NotificationService) { }


  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      processing: true,
      responsive: true,
     
     };
    this.GetBmResult()
  }



  GetBmResult(){
    this.BmResult.GetBmResult().subscribe((resp)=>{
      this.bmresult = resp.data
      this.bmresult.forEach(event => {
        event.runner = JSON.parse(event.runner);
        const resultRunner = event.runner.find(r => r.selId === event.result);
        event.runnername = resultRunner ? resultRunner.name : '--';
      });
      this.rerender()
    })
  }

  shareRunner(data) {
    if (typeof data.runner === 'string' && data.runner.startsWith('[{')) {
      try {
        this.runnerName = JSON.parse(data.runner);
      } catch (error) {
        console.error('Error parsing runner data:', error);
        this.runnerName = data.runner;
      }
    } else {
      this.runnerName = data.runner;
    }
    
    this.selectedGameId = data.gameId;
  }
  onRunnerChange(selId: string) {
    // const selected = this.runnerName.find(runner => runner.selId === selId);
    this.selID = selId
   
  }
  ReverseBM(){
    let bmData = {
      "result":this.selID,
      "gameId":this.selectedGameId
  }
    this.BmResult.GetReverseBmResult(bmData).subscribe((data)=>{
    if (data.status =="Success") {
      this.GetBmResult();
      $("[data-dismiss=modal]").trigger("click");
      this.notifyService.success(data.result);
    } else {
      this.notifyService.error(data.result);
    }
    })
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }

}
