import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserInfoComponent } from './user-info/user-info.component';

//Material Modules 
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from './Services/token.service';
import { TokenInterceptor } from './Services/token-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidenavService } from './Services/sidenav.service';
import { ScoreSetupComponent } from './score-setup/score-setup.component';
import { AllapiService } from './Services/allapi.service';
import { SessionUpdComponent } from './session-upd/session-upd.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './Services/login.service';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { TKfancyComponent } from './tkfancy/tkfancy.component';
import { AmanfancyComponent } from './amanfancy/amanfancy.component';
import { StoreAllapiComponent } from './store-allapi/store-allapi.component';
import { LaserwhitelistComponent } from './laserwhitelist/laserwhitelist.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DreamwhitelistComponent } from './dreamwhitelist/dreamwhitelist.component';
import { PremiumComponent } from './premium/premium.component';
import { DatePipe } from '@angular/common';
import { OlgacasinoComponent } from './casino_results/olgacasino/olgacasino.component';
import { SmartbetcasinoComponent } from './casino_results/smartbetcasino/smartbetcasino.component';
import { LasermaintenanceComponent } from './lasermaintenance/lasermaintenance.component';
import { AgentmanageComponent } from './agentmanage/agentmanage.component';
import { AwcwhitelistComponent } from './awcwhitelist/awcwhitelist.component';
import { PendingmarketsComponent } from './pendingmarkets/pendingmarkets.component';
import { PendingcasinoComponent } from './pendingcasino/pendingcasino.component';
import { PendingfancyComponent } from './pendingfancy/pendingfancy.component';
import { SettlementComponent } from './settlement/settlement.component';
import { SettlementService } from './settlement/settlement.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { ManagementComponent } from './management/management.component';
import { BmResultComponent } from './bm-result/bm-result.component';
import { FancyTypeComponent } from './fancy-type/fancy-type.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserInfoComponent,
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    ScoreSetupComponent,
    SessionUpdComponent,
    WhitelistComponent,
    TKfancyComponent,
    AmanfancyComponent,
    StoreAllapiComponent,
    LaserwhitelistComponent,
    DreamwhitelistComponent,
    PremiumComponent,
    OlgacasinoComponent,
    SmartbetcasinoComponent,
    LasermaintenanceComponent,
    AgentmanageComponent,
    AwcwhitelistComponent,
    PendingmarketsComponent,
    PendingcasinoComponent,
    PendingfancyComponent,
    SettlementComponent,
    ManagementComponent,
    BmResultComponent,
    FancyTypeComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatSnackBarModule,
    DataTablesModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSidenavModule,
    MatTabsModule,
    NgSelectModule,
    MatExpansionModule,
    BsDatepickerModule.forRoot(),

  ],
  providers: [
    CookieService,
    TokenService,
    SidenavService,
    LoginService,
    SettlementService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AllapiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
